import type {NumberedPagesPageInfo} from '@/components/blog/types';

export function generatePages(
  pagination: NumberedPagesPageInfo,
  currentPage: number,
) {
  const pages = [];
  const {hasNextPage, hasPreviousPage, hasThirdPage} = pagination;

  if (hasPreviousPage && currentPage > 1) {
    pages.push(currentPage - 1);
  }

  pages.push(currentPage);

  if (hasNextPage) {
    pages.push(currentPage + 1);
  } else if (hasPreviousPage && currentPage > 2) {
    pages.unshift(currentPage - 2);
  }

  if (currentPage === 1 && hasThirdPage) {
    pages.push(currentPage + 2);
  }

  return pages;
}

export function generatePrevNextURLs(
  pagination: NumberedPagesPageInfo,
  currentPage: number,
  currentURL: URL,
) {
  const {hasPreviousPage, hasNextPage} = pagination;

  const previousPageHref = new URL(currentURL);
  const nextPageHref = new URL(currentURL);

  if (hasPreviousPage) {
    previousPageHref.searchParams.set('page', (currentPage - 1).toString());
  }

  if (hasNextPage) {
    nextPageHref.searchParams.set('page', (currentPage + 1).toString());
  }

  return {previousPageHref, nextPageHref};
}
