import {cva} from 'class-variance-authority';

export const FakeCursorPaginationStyles = cva('flex mt-10', {
  variants: {
    mode: {
      default: 'justify-center',
      enterprise: 'font-faktum justify-between !font-medium',
      engineering: 'font-faktum justify-between !font-medium',
    },
  },
});

export const FakeCursorPaginationArrowWrapperStyles = cva('', {
  variants: {
    mode: {
      default: 'mx-7',
      enterprise: 'mx-0',
      engineering: 'mx-0',
    },
  },
});

export const FakeCursorPaginationArrowStyles = cva('', {
  variants: {
    mode: {
      default: '',
      enterprise: 'flex gap-x-3 items-center',
      engineering: 'flex gap-x-3 items-center',
    },
  },
});

export const FakeCursorPaginationItemWrapperStyles = cva('', {
  variants: {
    mode: {
      default: 'mx-5',
      enterprise:
        'text-button-light-secondary-border-active mx-0 !font-shopifysans',
      engineering:
        'text-button-light-secondary-border-active mx-0 !font-shopifysans',
    },
  },
});

export const FakeCursorPaginationItemCurrentStyles = cva('font-bold', {
  variants: {
    mode: {
      default: '',
      enterprise:
        'bg-marketingBg text-black border-b-2 border-[#69E075] px-5 py-4',
      engineering:
        'bg-engineering-light-author-text text-engineering-dark-text border-b-2 border-[#69E075] px-5 py-4',
    },
  },
});

export const FakeCursorPaginationItemStyles = cva('', {
  variants: {
    mode: {
      default: 'underline',
      enterprise:
        'text-button-light-secondary-border-active px-5 py-4 no-underline',
      engineering: 'text-engineering-dark-accent px-5 py-4 no-underline',
    },
  },
});

export const FakeCursorPaginationArrowTextStyles = cva('hidden', {
  variants: {
    mode: {
      default: '',
      enterprise: 'tablet-xl:flex text-black underline',
      engineering: 'tablet-xl:flex text-engineering-dark-text underline',
    },
  },
});

export const FakeCursorPaginationArrowTextDisabledStyles = cva('', {
  variants: {
    mode: {
      default: '',
      enterprise: 'text-shade-30',
      engineering: 'invisible',
    },
  },
});

export const FakeCursorPaginationArrowIconStyles = cva('', {
  variants: {
    mode: {
      default: '',
      enterprise: '',
      engineering: 'fill-engineering-dark-text',
    },
  },
});

export const FakeCursorPaginationArrowDisabledStyles = cva('', {
  variants: {
    mode: {
      default: '',
      enterprise: 'fill-shade-30',
      engineering: 'invisible',
    },
  },
});
