import {useCallback} from 'react';
import {ChevronLeftIcon, ChevronRightIcon} from '@shopify/polaris-icons';
import type {VariantProps} from 'class-variance-authority';

import Anchor from '@/components/shared/Anchor/Anchor';
import type {NumberedPagesPageInfo} from '@/components/blog/types';
import {useTranslations} from '@/hooks/useTranslations';
import {twMerge} from '@/stylesheets/twMerge';

import {generatePages, generatePrevNextURLs} from './utils';
import {
  FakeCursorPaginationStyles,
  FakeCursorPaginationArrowWrapperStyles,
  FakeCursorPaginationArrowStyles,
  FakeCursorPaginationItemWrapperStyles,
  FakeCursorPaginationItemCurrentStyles,
  FakeCursorPaginationItemStyles,
  FakeCursorPaginationArrowTextStyles,
  FakeCursorPaginationArrowTextDisabledStyles,
  FakeCursorPaginationArrowIconStyles,
  FakeCursorPaginationArrowDisabledStyles,
} from './styles';

interface FakeCursorPaginationProps
  extends VariantProps<typeof FakeCursorPaginationStyles> {
  pagination: NumberedPagesPageInfo;
  currentURL: URL;
}

export function FakeCursorPagination({
  pagination,
  currentURL,
  mode = 'default',
}: FakeCursorPaginationProps) {
  const currentPage = Number(currentURL.searchParams.get('page') || 1);
  const {t} = useTranslations();

  const pages = generatePages(pagination, currentPage);
  const {previousPageHref, nextPageHref} = generatePrevNextURLs(
    pagination,
    currentPage,
    currentURL,
  );

  const renderNextPrevButton = useCallback(
    (isLeft?: boolean, isDisabled?: boolean) => {
      return (
        <div className={FakeCursorPaginationArrowStyles({mode})}>
          {isLeft && (
            <ChevronLeftIcon
              width={20}
              height={20}
              className={twMerge(
                FakeCursorPaginationArrowIconStyles({mode}),
                isDisabled && FakeCursorPaginationArrowDisabledStyles({mode}),
              )}
            />
          )}
          <span
            className={twMerge(
              FakeCursorPaginationArrowTextStyles({mode}),
              isDisabled && FakeCursorPaginationArrowTextDisabledStyles({mode}),
            )}
          >
            {t(isLeft ? 'pagination.prevBtn' : 'pagination.nextBtn')}
          </span>
          {!isLeft && (
            <ChevronRightIcon
              width={20}
              height={20}
              className={twMerge(
                FakeCursorPaginationArrowIconStyles({mode}),
                isDisabled && FakeCursorPaginationArrowDisabledStyles({mode}),
              )}
            />
          )}
        </div>
      );
    },
    [mode, t],
  );

  return (
    Boolean(pages.length) && (
      <div className={FakeCursorPaginationStyles({mode})}>
        <div className={FakeCursorPaginationArrowWrapperStyles({mode})}>
          {currentPage !== 1 ? (
            <Anchor href={previousPageHref.toString()}>
              {renderNextPrevButton(true, false)}
            </Anchor>
          ) : (
            renderNextPrevButton(true, true)
          )}
        </div>

        <ul className="flex justify-center">
          {pages.map((page: number) => {
            const urlCopy = new URL(currentURL);
            urlCopy.searchParams.set('page', page.toString());

            return (
              <li
                key={urlCopy.toString()}
                className={FakeCursorPaginationItemWrapperStyles({mode})}
              >
                {page === currentPage ? (
                  <span
                    className={FakeCursorPaginationItemCurrentStyles({mode})}
                  >
                    {page}
                  </span>
                ) : (
                  <Anchor
                    className={FakeCursorPaginationItemStyles({mode})}
                    href={urlCopy.toString()}
                  >
                    {page}
                  </Anchor>
                )}
              </li>
            );
          })}
        </ul>

        <div className={FakeCursorPaginationArrowWrapperStyles({mode})}>
          {pagination.hasNextPage ? (
            <Anchor href={nextPageHref.toString()}>
              {renderNextPrevButton(false, false)}
            </Anchor>
          ) : (
            renderNextPrevButton(false, true)
          )}
        </div>
      </div>
    )
  );
}
